

































import Game from '@/api/Game'
import { ItemFactory } from '@/services/Factories'
import { itemHoverConfig } from '@/services/Factories/Item/ItemFactory'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ShellParser extends Vue {
  shellToParse = '' as any
  numberOfDivs = 10

  parseItems = async (shellToParse) => {
    this.resetItems()
    let shellToParseJSON = JSON.parse(shellToParse)

    if (!Array.isArray(shellToParseJSON))
      shellToParseJSON = [shellToParseJSON]

    shellToParseJSON.forEach(async (item, index) => {
      const names = await Game.getItemsNames([item.itemInstance?.itemVNum || item.itemVNum])

      const parsedItem = new ItemFactory(item).buildItem({
        name: names[0].name,
        iconId: 0,
      })

      const displayShellElement = document.getElementById(`parsedShell-${index}`)

      displayShellElement!.innerHTML = itemHoverConfig(parsedItem, {
        fromShellParser: true
      }).content()
      displayShellElement?.classList.add('shell-item')
      displayShellElement?.classList.remove('d-none')
    })
  }

  resetItems() {
    const displayedElements = document.querySelectorAll('[id^="parsedShell-"]')

    displayedElements.forEach(e => {
      e.classList.add('d-none')
    })
  }
}
